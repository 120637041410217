// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "controllers";
import lucide from "lucide/dist/umd/lucide";

Rails.start();
ActiveStorage.start();
require("trix");
require("@rails/actiontext");
require("@nathanvda/cocoon");
require("bootstrap-daterangepicker/daterangepicker");
import "flowbite/dist/flowbite.turbo.js";

$(document).on("turbo:load", () => {
  initLibraries();
  setupPostMessageListener();
});
//

// $(document).on("turbo:frame-load", function (e) {
//   lucide.createIcons();
//   initDismisses();
//   initDropdowns();
// })

$(document).on("turbo:render", function (e) {
  initLibraries();
});

$(document).on("turbo:frame-render", function (e) {
  initLibraries();
});

// addEventListener("turbo:before-stream-render", (event) => {
//   const originalRender = event.detail.render;

//   event.detail.render = function (streamElement) {
//     originalRender(streamElement);
//     initLibraries();
//   };
// });

function initLibraries() {
  initFlowbite();
  lucide.createIcons();
  // Daterangepicker
  if (jQuery().daterangepicker) {
    if ($(".datetimepicker").length) {
      $(".datetimepicker").daterangepicker({
        locale: { format: "YYYY-MM-DD HH:mm" },
        singleDatePicker: true,
        timePicker: true,
        timePicker24Hour: true,
      });
    }
  }
}

function setupPostMessageListener() {
  window.addEventListener('message', function (event) {
    // TODO: Ignore messages from untrusted sources when in production
    // if (process.env.NODE_ENV === 'production' && event.origin.includes('localhost')) {
    //   return; // Ignorar mensagens do localhost em produção, independente da porta
    // }
    console.info('🟢 CRM: Message received');

    try {
      const data = JSON.parse(event.data);

      if (data.event === 'authenticate' && data.token) {
        console.info('🟢 CRM Event: authenticate');

        localStorage.setItem('jwtToken', data.token);
        
        authenticateWithDevise(event.origin);
      }
      
      if (data.event === 'redirect-to-deal' && data.dealId) {
        console.info('🟢 CRM Event: redirect-to-deal');
        
        window.parent.postMessage('clinicdesk-dashboard-app:redirect-to-deal', '*')

        window.location.href = `/accounts_embed/${data.accountId}/deals/${data.dealId}`;
      }
    } catch (err) {
      console.error('🔴 CRM: Error processing postMessage:', err);
    }
  });
}

function authenticateWithDevise(origin) {
  console.info('🟢 CRM: Authenticating...');
  const token = localStorage.getItem('jwtToken');

  if (token) {
    fetch('/api/v1/internal/authenticate_token', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('🟢 CRM: User authenticated', data);

          window.parent.postMessage('clinicdesk-dashboard-app:authenticated', '*')

          window.location.href = '/?embed=true';
        } else {
          console.error('🔴 CRM: Token validation failed');
        }
      })
      .catch(error => {
        console.error('🔴 CRM: Error authenticating user:', error);
      });
  } else {
    console.error('🔴 CRM: Could not authenticate user');
  }
}